import React, { useState, useEffect } from "react";
import io from "socket.io-client";


import './Maxconnect.css';

let socket;



//To add a site to an existing merchant

const Add_site = () => {
  const [merchantid, setMerchantid] = useState('');
  const [siteid, setSiteid] = useState('');
  const [sitepassword, setSitepassword] = useState('');
  const [websitename, setWebsitename] = useState('');
  const [registermsg, setRegistermsg] = useState('');
  
  const ENDPOINT = 'https://maxconnect-systems.com/';
  useEffect(()=>{
    socket = io(ENDPOINT);
  },[ENDPOINT]);
  const register = () => {
    socket.emit('register_site', { merchantid:merchantid,siteid:siteid,sitepassword:sitepassword,websitename:websitename});
    setRegistermsg("Site added Successfully");
  }

  return (
    <div className="joinOuterContainer">
      <div className="joinInnerContainer">
        <h1 className="heading">新規サイトの追加</h1>
        <div>
          <input placeholder="Merchant ID" className="joinInput mt-10" type="text" onChange={(event) => setMerchantid(event.target.value)} />
        </div>        
        <div>
          <input placeholder="Site ID" className="joinInput mt-10" type="text" onChange={(event) => setSiteid(event.target.value)} />
        </div>
        <div>
          <input placeholder="Site Password" className="joinInput mt-10" type="text" onChange={(event) => setSitepassword(event.target.value)} />
        </div>
        <div>
          <input placeholder="Website" className="joinInput mt-10" type="text" onChange={(event) => setWebsitename(event.target.value)} />
        </div>                        
        <button className={'button mt-10'} type="submit" onClick={register}>登録</button>
      </div>
      <h4 style={{color: "white"}}>{registermsg}</h4>
    </div>
  );
  
}

export default Add_site;
