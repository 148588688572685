import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import queryString from 'query-string';
import * as RBC from "react-bootstrap";
import background from "../Chat/flower_pot.jpg";
import './Sites.css';

let socket;
let history;
export default function Sites({ location }) {
  const [sites, setSites] = useState([]);
  const [merchantid,setMerchantid] = useState('');
  const ENDPOINT = 'https://maxconnect-systems.com/';
  history = useHistory();
  useEffect(()=>{
    var log_out = 1;
    if(window.localStorage.getItem("status") === null || window.localStorage.getItem('status')==='LoggedOut'){
      history.push(`/`); 
    }else{
      log_out=0;
    }
    
    socket = io(ENDPOINT);    
    if(log_out===0){
      const { merchantid } = queryString.parse(location.search);
      setMerchantid(merchantid);      
    }    
    // if(window.localStorage.getItem('status')=='LoggedIn'){
    //   // setName(window.localStorage.getItem('username'));
    //   history.push(`/chat`); 
    // }
  },[ENDPOINT, location.search]);



  useEffect(()=>{
    socket.on('all_sites',({ss})=>{
      console.log(ss);
      setSites(sites => [ ...ss ]);
    })             
  });

  const get_sites_func = () => {
    socket.emit('get_sites', { merchant_id:merchantid});
  }

  const logout = () => {
    window.localStorage.setItem('status', 'LoggedOut');
    history.push(`/`); 
  }  

  return (
    <div>

      <div>
        <RBC.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <img src={require('../Chat/mc_logo-withoutbg.png')} height={45} width={45} style={{marginRight: '5px'}} />        
          <RBC.Navbar.Brand>チャットサービス確認画面</RBC.Navbar.Brand>
          <RBC.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <RBC.Navbar.Collapse id="responsive-navbar-nav">
            <RBC.Nav className="mr-auto">
              <button onClick={logout}>ログアウト</button>
            </RBC.Nav>
          </RBC.Navbar.Collapse>
        </RBC.Navbar>
      </div>


      <div style={{backgroundImage: `url(${background})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}} className="joinOuterSitesContainer">
        <div className="joinInnerSitesContainer">
          <h1 className="Sitesheading">加盟店様管理画面へようこそ</h1>
          <button className={'Sitesbutton mt-20'} type="submit" onClick={get_sites_func}>サイトIDを選択</button>
          <div>
            {sites.map(site=>(
              <div>
              <button className={'Sitesbutton2 mt-10'} onClick={()=>{history.push(`/chat?site_id=${site.site_id}&site_pass=${site.site_password}`);}}>{site.site_id}</button>
              </div>
            ))}
          </div>        
        </div>
      </div>

    </div>
  );
}
