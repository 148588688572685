import React from 'react';

import ScrollToBottom from 'react-scroll-to-bottom';


import './ViewUsers.css';

const ViewUsers = ({ users, setActiveuser, activeuserid, setActiveuserid, update }) => (
  <ScrollToBottom className="Historymessages">
      {users.map((user,i)=>(
        <div className="userbutton">
        <button className={i===activeuserid ? 'activebutton' : ''} key={i} onClick={()=>{setActiveuser(user.userid);setActiveuserid(i);update(user.chatid)}}>お客様 {user.userid}</button>
        </div>
      ))}
  </ScrollToBottom>
);

export default ViewUsers;