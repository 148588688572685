import React, { useState, useEffect } from "react";

//to retrive data from url
import queryString from 'query-string';
import io from "socket.io-client";

//to style the page
import CustomerMessages from '../CustomerMessages/CustomerMessages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';

import './CustomerChat.css';

let socket;

//location comes as prop from react router
const CustomerChat = ({ location }) => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [chatid, setChatid] = useState('');
  const [roomname,setRoomname] = useState('');
  const [merchantstatus,setMerchantstatus] = useState(0);

  const ENDPOINT = 'https://maxconnect-systems.com/';

  const usertype = 'customer';


  //it will run when this chat component renders
  useEffect(() => {
    const { username } = queryString.parse(location.search);

    socket = io(ENDPOINT);

    setUsername(username)

    //Change here for new site/merchant
    //Roomname is set using the site id
    const rname = "90000311";

    socket.emit('join', { username , usertype , rname:rname}, (error) => {
      if(error) {
        alert(error);
      }
    });
  }, [ENDPOINT, location.search]);
  
  useEffect(() => {
    //when message is received from server we add that to the messages array.
    socket.on('message', message => {
      setMessages(messages => [ ...messages, message ]);
      console.log(message);
    });  

    socket.on("chat_id_room_name", ({chat_id, room_name}) => {
      console.log(chat_id);
      setChatid(chat_id);
      setRoomname(room_name);
    });

    socket.on('merchant_offline',off => {
      setMerchantstatus(off);
    });

}, []);

  const sendMessage = (event) => {

    //when a button is press, the browser is reloaded, so to prevent that default behaviour below line is used.
    event.preventDefault();

    if(message) {
      const mm = { user: username, text: message};
      setMessages(messages => [ ...messages, mm ]);
      socket.emit('sendMessage', {message , usertype, socket_id:'a',chat_id:chatid,roomname:roomname}, () => setMessage(''));
    }
  }

  if(merchantstatus===0){
    return (
      <div className="myouterContainer">
        <div className="mycontainer">
            <InfoBar />
            <CustomerMessages messages={messages} name={username} />
            <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
        </div>
      </div>
    );
  }else{
    return (
      <div className="myouterContainer">
        <div className="mycontainer">
            <InfoBar />
            <CustomerMessages messages={messages} name={username} />
        </div>
      </div>
    );    
  }

}

export default CustomerChat;
