import React from 'react';

import ScrollToBottom from 'react-scroll-to-bottom';


import './ViewUsers.css';


const ViewUsers = ({ users, setActiveuser, activeuserid, setActiveuserid, update_messages }) => (
  <ScrollToBottom className="Historymessages">
        {users.map((user,i)=>(
          <div>
            <button className={i===activeuserid ? 'activebutton' : ''} key={i} onClick={()=>{setActiveuser(user.user_id);setActiveuserid(i);update_messages(user.chat_id);}}>お客様番号 {user.user_id}</button>
          </div>
        ))}
  </ScrollToBottom>
);

export default ViewUsers;