import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import './maxconnectJoin.css';

let socket;
let history;
//Maxconnect Portal login Page
export default function SignIn() {
  const [maxconnectid, setMaxconnectid] = useState('');
  const [password, setPassword] = useState('');
  const [incorrectmsg, setIncorrectmsg] = useState('');
  const ENDPOINT = 'https://maxconnect-systems.com/';
  history = useHistory();
  useEffect(()=>{
    socket = io(ENDPOINT);
    if(window.localStorage.getItem('max_status')==='LoggedIn'){
      // setName(window.localStorage.getItem('username'));
      // const maxid = window.localStorage.getItem('maxconnect_id');
      history.push(`/maxconnect/add`); 
    }
  },[ENDPOINT]);

  useEffect(()=>{
    socket.on('max_log_in',({maxconnect_id}) => {
      window.localStorage.setItem('max_status', 'LoggedIn');
      // window.localStorage.setItem('merchant_id',merchant_id);
      history.push(`/maxconnect/add`);  
    })
    socket.on('max_failed',() => {
      setIncorrectmsg("もう一度入力して下さい");
    })

  },[ENDPOINT]);

  //function to verify id and password
  const verify = () => {  
    socket.emit('max_verify', { maxconnect_id:maxconnectid, pass:password });
  }


  return (
    <div className="joinOuterContainer">
      <div className="joinInnerContainer-maxconnectlogin">
        <h2 className="joinheading-maxconnectlogin">チャットサービス社内管理画面</h2>
        <div>
          <input placeholder="MaxconnectID" className="joinInput-maxconnectlogin mt-20" type="text" onChange={(event) => setMaxconnectid(event.target.value)} />
        </div>
        <div>
          <input placeholder="パスワード" className="joinInput-maxconnectlogin mt-20" type="password" onChange={(event) => setPassword(event.target.value)} />
        </div>
        <button className={'button-maxconnectlogin mt-20'} type="submit" onClick={verify}>サインイン</button>
        <h4 style={{color: "black"}}>{incorrectmsg}</h4>
      </div>
    </div>
  );
}
