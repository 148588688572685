import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import './View_chat.css';

import Messages from './ViewMessages/ViewMessages';
import Users_view from './ViewUsers/ViewUsers';

let socket;

// import './Maxconnect.css';


const View_chat = () => {

  const ENDPOINT = 'https://maxconnect-systems.com/';
  const [merchants, setMerchants] = useState([]);
  const [sites, setSites] = useState([]);
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [activemerchantid, setActivemerchantid] = useState('');
  const [activesiteid, setActivesiteid] = useState('');  
  const [activeuserid, setActiveuserid] = useState('');  
  const [activeuser, setActiveuser] = useState('');


  useEffect(()=>{
    socket = io(ENDPOINT);
  },[ENDPOINT]);

  useEffect(()=>{
      socket.on('all_merchants_to_maxconnect',({merchant})=>{
        setMerchants(merchants => [ ...merchant ]);     
      })
  },[merchants]);

  useEffect(()=>{
      socket.on('all_sites_to_maxconnect',({site})=>{
        setSites(sites => [ ...site ]);     
      })    
    },[sites])

  useEffect(()=>{
      socket.on('all_users_to_maxconnect',({user})=>{
        setUsers(users => [ ...user ]);     
      })
    },[users])

  useEffect(()=>{
      socket.on('all_messages_to_maxconnect',({message})=>{
        setMessages(messages => [ ...message ]);     
      })                            
    },[messages])


  const reload = () =>{
    socket.emit('get_merchants_for_maxconnect', {}, (error) => {
      if(error) {
        alert(error);
      }
    }); 
    setSites(sites => []);
    setUsers(users => []);
    setMessages(messages => []);  
    setActivemerchantid('');
    setActiveuser('');
  }

  const update_sites = (merchantid) =>{
    socket.emit('get_sites_for_maxconnect', { merchant_id:merchantid }, (error) => {
      if(error) {
        alert(error);
      }
    });
    setUsers(users => []);
    setMessages(messages => []);      
    setActivesiteid(''); 
    setActiveuser('');
  }  

  const update_users = (siteid) => {
    socket.emit('get_users_for_maxconnect', { siteid:siteid }, (error) => {
      if(error) {
        alert(error);
      }
    });
    setMessages(messages => []);   
    setActiveuserid(''); 
    setActiveuser('');
  }

  const update_messages = (chatid) => {
    socket.emit('get_messages_for_maxconnect', { chatid:chatid }, (error) => {
      if(error) {
        alert(error);
      }
    }); 
  }

  return (
    <div className="greycolor">
    <div>
     <button className="merchant_load_button" onClick={reload}>加盟店登録情報更新</button>
    </div>


    <div class="row">
      <div class="column">
        <h2>加盟店ID</h2>
        {merchants.map((merchant,i)=>(
          <div>
            <button className={i===activemerchantid ? 'activebutton' : ''} key={i} onClick={()=>{setActivemerchantid(i);update_sites(merchant.merchant_id);}}>{merchant.merchant_id}</button>
          </div>
        ))}
      </div>
      <div class="column">
        <h2>サイトID</h2>
        {sites.map((site,i)=>(
          <div>
            <button className={i===activesiteid ? 'activebutton' : ''} key={i} onClick={()=>{setActivesiteid(i);update_users(site.site_id);}}>{site.site_id}</button>
          </div>
        ))}
      </div>
      <div class="column">
        <h2>お客様</h2>
          <div className="myViewuserscontainer">
            <Users_view users={users} setActiveuser={setActiveuser} activeuserid={activeuserid} setActiveuserid={setActiveuserid} update_messages={update_messages} />
          </div>
      </div>
      <div class="lastcolumn1">
        <h2>メッセージ</h2>
          {activeuser?
            <h4 className="Bluecolor">お客様番号 {activeuser}</h4>
            :
            ""
          }
          <div className="myViewmessagecontainer">
            <Messages messages={messages}/>
          </div>
           
      </div>
    </div>

    </div>
  );
  
}

export default View_chat;
