import React from 'react';

import ScrollToBottom from 'react-scroll-to-bottom';

import CustomerMessage from './CustomerMessage/CustomerMessage';

import './CustomerMessages.css';

const CustomerMessages = ({ messages, name }) => (
  <ScrollToBottom className="messages">
    {messages.map((message, i) => <div key={i}><CustomerMessage message={message} name={name}/></div>)}
  </ScrollToBottom>
);

export default CustomerMessages;