const customers = new Map();
const new_message = new Map();

const messageAddcustomer = (userid) => {
	new_message.set(userid,0);
}

const messageUpdate = (userid,val) => {
	new_message.set(userid,val);
}

const messageCheck = (userid) => {
	return new_message.get(userid);
}

const messageClear = () => {
  new_message.clear();
}

const addcustomer = (userid) => {
  customers.set(userid,[]);
  return customers.get(userid);
}

const checkcustomer = (userid) => {
	return customers.has(userid);
}

// const remcustomer = ( userid ) => {
//   customers.delete(userid);
// }

const addmessage = (userid,message,sendertype) => {
  const message_data = {sendertype,message}		
  customers.get(userid).push(message_data);
  return userid
}

const viewmsg = ( userid ) => {
  return customers.get(userid);
}

const numofCustomer = () => {
  return customers.size;
}

const customerClear = () => {
  customers.clear();
}

module.exports = { addcustomer, checkcustomer, addmessage, numofCustomer, viewmsg , messageAddcustomer, messageUpdate , messageCheck,messageClear, customerClear };
