import React from 'react';
import Moment from 'moment';

import './HistoryMessage.css';


const HistoryMessage = ({ message, is_merchant, msg_time }) => {

  const formattedDate = Moment(msg_time).format("lll");

  
  return (
    is_merchant
      ? (
        <div className="messageContainer justifyEnd">
          <p className="sentText pr-10">{formattedDate}</p>
          <div className="messageBox backgroundBlue">
            <p className="messageText colorWhite">{message}</p>
          </div>
        </div>
        )
        : (
          <div className="messageContainer justifyStart">
            <div className="messageBox backgroundLight">
              <p className="messageText colorDark">{message}</p>
            </div>
            <p className="sentText pl-10">{formattedDate}</p>
          </div>
        )
  );
}

export default HistoryMessage;