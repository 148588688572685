import React, { useState, useEffect } from "react";
import * as RBC from "react-bootstrap";
import './bootstrap.css';
//to retrive data from url
import queryString from 'query-string';
import io from "socket.io-client";
import { useHistory } from "react-router-dom";

//to style the page
import TextContainer from '../TextContainer/TextContainer';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';
import InfoBar_nouser from '../InfoBar_nouser/InfoBar_nouser';
import PriceBar from '../PriceBar/PriceBar';
import background from "./flower_pot.jpg";

import './Chat.css';

const { addcustomer, checkcustomer, addmessage, numofCustomer, viewmsg , messageAddcustomer, messageUpdate , messageCheck , customerClear, messageClear} = require('./helper');

let socket;
let history;


//location comes as prop from react router
const Chat = ({ location }) => {
  const [username, setUsername] = useState('');
  // const [merchantid, setMerchantid] = useState('');
  const [siteid,setSiteid] = useState('');
  const [sitepassword,setSitepassword] = useState('');



  const [room, setRoom] = useState('');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const [activeuser, setActiveuser] = useState('');        //stores the active user-id of the user
  const [activeid, setActiveid] = useState('newid'); 
  const [msgadded, setMsgadded] = useState('');  //this is used to just tell message component that new message is added to map (to reload properly) 

  const[payamount,setPayamount] = useState('');

  const [inactivetext,setInactivetest] = useState('チャットサービスを離席中に変更');  

  const ENDPOINT = 'https://maxconnect-systems.com/';
  // var m = new Map();


  
  const usertype = 'merchant';
  history = useHistory();



  //it will run when this chat component renders
  useEffect(() => {
    var log_out = 1;
    if(window.localStorage.getItem("status") === null || window.localStorage.getItem('status')==='LoggedOut'){
      history.push(`/`); 
    }else{
      log_out=0;
    }
    // const { username } = queryString.parse(location.search);
    setUsername("merchant");

    socket = io(ENDPOINT);

    setUsername(username)

    // const merchantid = window.localStorage.getItem('merchant_id');
    // setMerchantid(window.localStorage.getItem('merchant_id'));

    var pp = queryString.parse(location.search);
    const site_id_for_emit = pp.site_id;
    if(log_out===0){
      socket.emit('join', { username, usertype , siteid:site_id_for_emit}, (error) => {
        if(error) {
          alert(error);
        }
      });      
    }

  }, [ENDPOINT, location.search]);

  useEffect(() => {
    var parsed = queryString.parse(location.search);    
    setSiteid(parsed.site_id);
    setSitepassword(parsed.site_pass);
  });

  //creating entry in map when receiving new customer
  useEffect(() => {
    socket.on('customerJoin',({user_id}) => {
      console.log(user_id);
      console.log(addcustomer(user_id));
      messageAddcustomer(user_id);
    });

  });
   


  useEffect(() => {
    //when message is received from server we add that to the messages array.
    socket.on('message', message => {
      setMessages(messages => [ ...messages, message ]);
      if(message.socket_id==='a'){

      }else{
        console.log('on receiving msg');
        console.log(numofCustomer());
        console.log(message.user)
        console.log(viewmsg(message.user));
        const sendertype = "customer";
        //We need check here if the socket id is there in the map or not.....if not then we need to add it first (the case of reload)
        console.log(addmessage(message.user,message.text,sendertype));
        messageUpdate(message.user,1);
        setMsgadded(message.text);

        console.log(viewmsg(message.user));
        // viewmsg(message.socket_id);
      }
    });


    socket.on("roomData", ({ room, users }) => {
      

      setRoom(room);
      setUsers(users);
      users.map(function(customer) {
        if(!checkcustomer(customer.userid)){
          addcustomer(customer.userid);
          messageAddcustomer(customer.userid);
        }
      })
    });

    // socket.on("lasthour",({user_array}) => {
    //   console.log(user_array);
    //   setLasthourusers(user_array);
    //   console.log(lasthourusers);
    // })

}, []);


  // function validURL(str) {
  //   var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  //     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  //   return !!pattern.test(str);
  // }

  const sendMessage = (event) => {
    //when a button is press, the browser is reloaded, so to prevent that default behaviour below line is used.
    event.preventDefault();
    
    if(payamount) {
      const message =  payamount;
      const mm = { user: username, text: message}; 
      setMessages(messages => [ ...messages, mm ]); 
      const sendertype = "merchant"   
      addmessage(activeuser,mm.text,sendertype);
      const ispayURL = 1;
      messageUpdate(activeuser,0);
      socket.emit('sendMessage', {message , usertype, socket_id:activeid, activeuserid: activeuser, ispayURL:ispayURL,siteid:siteid,sitepass:sitepassword}, () => setMessage(''));
      setPayamount('');       
    }

    if(message) {
      const mm = { user: username, text: message};
      setMessages(messages => [ ...messages, mm ]);   
      const sendertype = "merchant"   
      addmessage(activeuser,mm.text,sendertype);
      const ispayURL = 0;
      messageUpdate(activeuser,0);
      socket.emit('sendMessage', {message , usertype, socket_id:activeid, activeuserid: activeuser, ispayURL:ispayURL,siteid:siteid,sitepass:sitepassword}, () => setMessage(''));
    }
  }

  const logout = () => {
    socket.emit('disconnect');
    window.localStorage.setItem('status', 'LoggedOut');
    history.push(`/`); 
  }

  const back_to_sites = () => {
    const mid = window.localStorage.getItem('merchant_id');
    history.push(`/sites?merchantid=${mid}`);   
  }

  const histories = () => {
    // ref.current.scrollIntoView({
    //       behavior: 'smooth',
    //       block: 'start',
    //     });
    window.open(`https://maxconnect-chat-service.com/history?room=${room}`);
  }

  const messCheck = (userid) => {
    return messageCheck(userid);
  }  

  const setinactive = () => {
    if(inactivetext==='チャットサービスを離席中に変更'){
      setActiveuser('');
      customerClear();
      messageClear();
      setUsers('');
      //empty users and message array
      setInactivetest('チャットサービスをオンラインに変更');
      socket.emit('setinactivestate', { room: room});
    }else{
      var pp = queryString.parse(location.search);
      const site_id_for_emit = pp.site_id;
      setInactivetest('チャットサービスを離席中に変更');
      socket.emit('setactivestate',{siteid:site_id_for_emit});
    }

  }

  if(activeuser===''){
  return (
    <div>
      <div>
      <div>  
        <RBC.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <img src={require('./mc_logo-withoutbg.png')} height={45} width={45} style={{marginRight: '5px'}} />
          <RBC.Navbar.Brand >チャットサービス確認画面</RBC.Navbar.Brand>
          <RBC.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <RBC.Navbar.Collapse id="responsive-navbar-nav">
            <RBC.Nav className="mr-auto">
              <button onClick={back_to_sites}>戻る</button>
            </RBC.Nav>
            <RBC.Nav className="mr-auto">
              <button className={inactivetext==="チャットサービスを離席中に変更" ? 'redbutton' : 'greenbutton'} onClick={setinactive}>{inactivetext}</button>
            </RBC.Nav>
            <RBC.Nav>
              <button onClick={histories}>履歴</button>
            </RBC.Nav>
            <RBC.Nav>
              <button onClick={logout}>ログアウト</button>
            </RBC.Nav>
          </RBC.Navbar.Collapse>
        </RBC.Navbar>
      </div>  
      </div>
      <div style={{backgroundImage: `url(${background})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}} className="myouterChatContainer">
        <InfoBar_nouser />
        {
          (inactivetext==='チャットサービスを離席中に変更')
            ? <TextContainer users={users} name={username} setActiveuser={setActiveuser} activeuser={activeuser} setActiveid={setActiveid} activeid={activeid} messCheck={messCheck}/>
            : null
        }
        
      </div>
    </div>
  );

  }else{
  return (
    <div>
      <div>
      <div>  
        <RBC.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <img src={require('./mc_logo-withoutbg.png')} height={45} width={45} style={{marginRight: '5px'}} />
          <RBC.Navbar.Brand>チャットサービス確認画面</RBC.Navbar.Brand>
          <RBC.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <RBC.Navbar.Collapse id="responsive-navbar-nav">
            <RBC.Nav className="mr-auto">
              <button onClick={back_to_sites}>戻る</button>
            </RBC.Nav> 
            <RBC.Nav className="mr-auto">
              <button className={inactivetext==="チャットサービスを離席中に変更" ? 'redbutton' : 'greenbutton'} onClick={setinactive}>{inactivetext}</button>
            </RBC.Nav>
            <RBC.Nav>
              <button onClick={histories}>履歴</button>
            </RBC.Nav>
            <RBC.Nav>
              <button onClick={logout}>ログアウト</button>
            </RBC.Nav>
          </RBC.Navbar.Collapse>
        </RBC.Navbar>
      </div>  

      </div>
      <div style={{backgroundImage: `url(${background})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}} className="myouterChatContainer">
        <div className="myChatcontainer">            
            <InfoBar userid={activeuser} />
            <PriceBar setPayamount={setPayamount} sendMessage={sendMessage}/>
            <Messages messages={viewmsg(activeuser)} name={activeuser} mesadded={msgadded} />
            <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
        </div>
        {
          (inactivetext==='チャットサービスを離席中に変更')
            ? <TextContainer users={users} name={username} setActiveuser={setActiveuser} activeuser={activeuser} setActiveid={setActiveid} activeid={activeid} messCheck={messCheck}/>
            : null
        }
      </div>

    </div>
  );
  }
}

export default Chat;
