import React from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import onlineIcon from '../../icons/onlineIcon.png';

import './TextContainer.css';



const TextContainer = ({ users , name , setActiveuser, activeuser , setActiveid ,activeid, messCheck}) => {


  return(

  <div className="mytextContainer">
    {

      users
        ? (
          <div>
            <h1 className="textcontainerheading">オンラインのお客様</h1>
            <div className="myactiveContainer">  
              <h2>              
                {users.map(({userid,socketid}) => (
                  <div key={socketid} className="myactiveItem">
                    <button type="button" className='btn btn-primary btn-sm' id="sidbarPush" onClick={()=>{setActiveuser(userid);setActiveid(socketid)}}>User{userid}</button>
                    <img alt="Online Icon" src={onlineIcon}/>
                    {messCheck(userid)?
                      <h6 className="newindicator">New</h6>
                        :
                      <h6></h6>  
                    }
                  </div>
                  ))}    
              </h2>                
            </div>
          </div>
        )
        : (
          <div>
            <h1 className="textcontainerheading">オンラインのお客様</h1>
            <div className="myactiveContainer">  

            </div>
          </div>
          )
    }
  </div>
);

}  

export default TextContainer;