import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import background from "../Chat/flower_pot.jpg";
import './Join.css';

let socket;
let history;
export default function SignIn() {
  const [merchantid, setMerchantid] = useState('');
  const [password, setPassword] = useState('');
  const [incorrectmsg, setIncorrectmsg] = useState('');
  const ENDPOINT = 'https://maxconnect-systems.com/';
  history = useHistory();
  useEffect(()=>{
    socket = io(ENDPOINT);
    if(window.localStorage.getItem('status')==='LoggedIn'){
      // setName(window.localStorage.getItem('username'));
      const merchid = window.localStorage.getItem('merchant_id');
      history.push(`/sites?merchantid=${merchid}`); 
    }
  },[ENDPOINT]);

  useEffect(()=>{
    socket.on('log_in',({merchant_id}) => {
      window.localStorage.setItem('status', 'LoggedIn');
      window.localStorage.setItem('merchant_id',merchant_id);
      history.push(`/sites?merchantid=${merchant_id}`);  
    })
    socket.on('failed',() => {
      setIncorrectmsg("もう一度入力して下さい");
    })

  },[ENDPOINT]);

  const verify = () => {
    socket.emit('verify', { merchant_id:merchantid, pass:password });
  }

  return (
    <div style={{backgroundImage: `url(${background})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}} className="joinOuterContainer">
      <div className="joinInnerContainer-merchantlogin">
        <h2 className="joinheading-merchantlogin">チャットサービス加盟店様ログイン</h2>
        <div>
          <input placeholder="加盟店ID" className="joinInput-merchantlogin mt-20" type="text" onChange={(event) => setMerchantid(event.target.value)} />
        </div>
        <div>
          <input placeholder="パスワード" className="joinInput-merchantlogin mt-20" type="password" onChange={(event) => setPassword(event.target.value)} />
        </div>
        <button className={'button-merchantlogin mt-20'} type="submit" onClick={verify}>サインイン</button>
        <h4 style={{color: "black"}}>{incorrectmsg}</h4>
      </div>
    </div>
  );
}
