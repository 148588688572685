import React from 'react';


import './PriceBar.css';

const PriceBar = ({ setPayamount, sendMessage }) => (
  <div className="PriceContainer">
    <div className="priceleftInnerContainer">
      <input className="form-control" placeholder='決済金額を入力' onChange={({ target: { value } }) => setPayamount(value)}/>
    </div>
    <div className="pricerightInnerContainer">
    	<button type="submit" onClick={e => sendMessage(e)}>決済ボタンを作成</button>
    </div>
  </div>
);

export default PriceBar;