import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import * as RBC from "react-bootstrap";

import io from "socket.io-client";
import './History.css';
import Users_view from './ViewUsers/ViewUsers'

import Messages from './HistoryMessages/HistoryMessages';

let socket;



function History ({ location }) {

  const ENDPOINT = 'https://maxconnect-systems.com/';
  const [room, setRoom] = useState(''); 
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [activeuserid, setActiveuserid] = useState('');  
  const [activeuser, setActiveuser] = useState('');

  useEffect(()=>{
    const { room } = queryString.parse(location.search);
    setRoom(room);
    console.log(room);
    socket = io(ENDPOINT);


  },[ENDPOINT, location.search]);



  useEffect(()=>{
      socket.on('topusers',({uss})=>{
        setUsers(users => [ ...uss ]);
      })             
  });

  useEffect(()=>{
      socket.on('messages_of_selected',({mes})=>{
        setMessages([]);
        setMessages(messages => [ ...mes ]);    
      })  
    });



  const reload = () =>{
    socket.emit('latest_users', { roomname:room }, (error) => {
      if(error) {
        alert(error);
      }
    }); 
    setActiveuser('');
    setMessages([]);
    setActiveuserid('');
  }

  const update = (chatid) =>{
    socket.emit('get_message_history', { chatid:chatid }, (error) => {
      if(error) {
        alert(error);
      }
    }); 
  }

  return (
    <div className="Historybody">
    <div>
    <RBC.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <img src={require('../Chat/mc_logo-withoutbg.png')} height={45} width={45} style={{marginRight: '5px'}} />
          <RBC.Navbar.Brand>チャット履歴</RBC.Navbar.Brand>
          <RBC.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <RBC.Navbar.Collapse id="responsive-navbar-nav">
          <RBC.Nav className="mr-auto">
                <button onClick={reload}>更新</button>
          </RBC.Nav>
          <RBC.Nav>
          </RBC.Nav>
          </RBC.Navbar.Collapse>
        </RBC.Navbar> 
    </div>
    
    <div class="row">
      <div class="firstcolumn">
        <h2>お客様</h2>
          <div className="myHistoryusercontainer">
            <Users_view users={users} setActiveuser={setActiveuser} activeuserid={activeuserid} setActiveuserid={setActiveuserid} update={update} />
          </div>
      </div>
      <div class="lastcolumn">
        <h2>チャット内容</h2>
          {activeuser?
            <h4 className="Bluecolor">お客様ID = {activeuser}</h4>
            :
            ""
          }
          <div className="myHistorycontainer">
            <Messages messages={messages}/>
          </div>      
      </div>
    </div>
    
    </div>
  );
  
}

export default History;
