 import React from 'react';

import Chat from './components/Chat/Chat';
import Join from './components/Join/Join';
import Maxconnect from './components/maxconnect-portal/Maxconnect.js';
import Addmerchant from './components/maxconnect-portal/Add_merchant.js';
import ViewChat from './components/maxconnect-portal/View_chat.js';
import HistoryforMerchants from './components/History/History.js';
import Sites from './components/sites/Sites.js';
import Add_sites from './components/maxconnect-portal/Add_site.js';
import MaxconnectLogin from './components/maxconnect-portal/maxconnectJoin/maxconnectJoin.js'; 



import CustomerChat_999999_99999995 from './components/customer/999999/99999995/CustomerChat/CustomerChat.js';
import CustomerJoin_999999_99999995 from './components/customer/999999/99999995/CustomerJoin/CustomerJoin.js';
import CustomerChat_999999_99999996 from './components/customer/999999/99999996/CustomerChat/CustomerChat.js';
import CustomerJoin_999999_99999996 from './components/customer/999999/99999996/CustomerJoin/CustomerJoin.js';

import CustomerChat_900003_90000311 from './components/customer/900003/90000311/CustomerChat/CustomerChat.js';
import CustomerJoin_900003_90000311 from './components/customer/900003/90000311/CustomerJoin/CustomerJoin.js';

import CustomerChat_900003_90000311_english from './components/customer/900003/90000311_english/CustomerChat/CustomerChat.js';
import CustomerJoin_900003_90000311_english from './components/customer/900003/90000311_english/CustomerJoin/CustomerJoin.js';

import { BrowserRouter as Router, Route } from "react-router-dom";

// exact keyword indicates the exact path while in the second one we have kept it simple because we will pass the parameters in the address.
const App = () => {
  return (
    <Router>
      <Route path="/" exact component={Join} />
      <Route path="/chat" component={Chat} />
      <Route path="/maxconnect" component={Maxconnect} />
      <Route path="/maxconnect/add" component={Addmerchant} />
      <Route path="/maxconnect/chats" component={ViewChat} /> 
      <Route path="/history" component={HistoryforMerchants} />
      <Route path="/sites" component={Sites} />
      <Route path="/maxconnect/add_site" component={Add_sites} />           
      <Route path="/masterpanel" component={MaxconnectLogin} />

      <Route path="/customer_999999_99999995" component={CustomerChat_999999_99999995} />
      <Route path="/website_999999_99999995" component={CustomerJoin_999999_99999995} />

      <Route path="/customer_999999_99999996" component={CustomerChat_999999_99999996} />
      <Route path="/website_999999_99999996" component={CustomerJoin_999999_99999996} />

      <Route path="/customer_900003_90000311" component={CustomerChat_900003_90000311} />
      <Route path="/website_900003_90000311" component={CustomerJoin_900003_90000311} />

      <Route path="/customer_900003_90000311_english" component={CustomerChat_900003_90000311_english} />
      <Route path="/website_900003_90000311_english" component={CustomerJoin_900003_90000311_english} />      


    </Router>
  );
}

export default App;
