import React, { useState, useEffect } from "react";
import * as RBC from "react-bootstrap";
import io from "socket.io-client";
import { useHistory } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.css';
import './new.css';
import './Maxconnect.css';
import logo from '../../icons/logo.png';
import background from "../Chat/background_image.jpg";

let socket;
let history;

const Maxconnect = () => {

  const ENDPOINT = 'https://maxconnect-systems.com/';  
  history = useHistory();

  useEffect(() => {
    var log_out = 1;
    if(window.localStorage.getItem("max_status") === null || window.localStorage.getItem('max_status')==='LoggedOut'){
      history.push(`/masterpanel`); 
    }else{
      log_out=0;
    }
  
  socket = io(ENDPOINT);

  }, [ENDPOINT]);

  const logout = () => {
    window.localStorage.setItem('max_status', 'LoggedOut');
    history.push(`/masterpanel`); 
  }

  return (
    <div className = "mainBody">
    <div>  
      <RBC.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <img src={logo} alt="logo" width={50} height={50}/>
        <RBC.Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <RBC.Navbar.Collapse id="responsive-navbar-nav">
          <RBC.Nav>
            <RBC.Nav.Link href="/maxconnect/add">加盟店を追加</RBC.Nav.Link>
          </RBC.Nav>
          <RBC.Nav className="mr-auto">
            <RBC.Nav.Link href="/maxconnect/add_site">既存の加盟店にサイトを追加</RBC.Nav.Link>
          </RBC.Nav>          
          <RBC.Nav>
            <RBC.Nav.Link href="/maxconnect/chats">加盟店のチャットを確認</RBC.Nav.Link>
          </RBC.Nav>
          <RBC.Nav>
            <button onClick={logout}>ログアウト</button>
          </RBC.Nav>          
        </RBC.Navbar.Collapse>
      </RBC.Navbar>
    </div>
    </div>
  );
  
}

export default Maxconnect;
