import React from 'react';

import ScrollToBottom from 'react-scroll-to-bottom';

import HistoryMessage from './HistoryMessage/HistoryMessage';

import './HistoryMessages.css';

const HistoryMessages = ({ messages }) => (
  <ScrollToBottom className="Historymessages">
    {messages.map((message, i) => <div key={i}><HistoryMessage message={message.message} is_merchant={message.is_merchant} msg_time={message.msg_time} /></div>)}
  </ScrollToBottom>
);

export default HistoryMessages;