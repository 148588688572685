import React from 'react';

import './Message.css';


const Message = ({ message, name }) => {
  let isSentByCurrentUser = false;

  // const trimmedName = name.trim().toLowerCase();

  if(message.sendertype === "merchant") {
    isSentByCurrentUser = true;
  }

  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <div className="messageBox backgroundBlue">
            <p className="messageText colorWhite">{message.message}</p>
          </div>
        </div>
        )
        : (
          <div className="messageContainer justifyStart">
            <div className="messageBox backgroundLight">
              <p className="messageText colorDark">{message.message}</p>
            </div>
            <p className="sentText pl-10 ">User{name}</p>
          </div>
        )
  );
}

export default Message;