import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import './CustomerMessage.css';


let history;

const CustomerMessage = ({ message: { text, user ,ispayURL , siteid, sitepass, isretry}, name }) => {

  let isSentByCurrentUser = false;
  history = useHistory();
  const trimmedName = name.trim().toLowerCase();

  if(user === "customer_message") {
    isSentByCurrentUser = true;
  }



  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <div className="messageBox backgroundBlue">
            <p className="messageText colorWhite">{text}</p>
          </div>
        </div>
        )
        : 
          isretry
            ?
              <button className="pay-button" onClick={()=>{history.goBack()}}><span>リトライ</span></button>
            :
              ispayURL
                ?(<div>
                    <form method="post" action="https://mc-credit.com.sg/service/credit/input.html" target="_blank">
                      <input
                        type="hidden"
                        name="siteId"
                        value={siteid}
                      />
                      <input
                        type="hidden"
                        name="sitePass"
                        value={sitepass}
                      />
                      <input
                        type="hidden"
                        name="amount"
                        value={text}
                      />
                      <input
                        className="pay-button"
                        type="submit"
                        value="お支払いはこちら"
                      />
                    </form>
                  </div>

                  )
                  :      
                  (
                  <div className="messageContainer justifyStart">
                    <div className="messageBox backgroundLight">
                      <p className="messageText colorDark">{text}</p>
                    </div>
                  </div>
                  )
  );
}

export default CustomerMessage;