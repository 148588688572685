import React, { useState, useEffect } from "react";
import io from "socket.io-client";


import './Maxconnect.css';

let socket;


const Add_merchant = () => {
  const [name, setName] = useState('');
  const [merchantid, setMerchantid] = useState('');
  const [password, setPassword] = useState('');  
  const [siteid, setSiteid] = useState('');
  const [sitepassword, setSitepassword] = useState('');
  const [websitename, setWebsitename] = useState('');
  const [registermsg, setRegistermsg] = useState('');
  
  const ENDPOINT = 'https://maxconnect-systems.com/';
  
  useEffect(()=>{
    socket = io(ENDPOINT);
  },[ENDPOINT]);
  const register = () => {
    socket.emit('register', { fullname:name,merchantid:merchantid,password:password,siteid:siteid,sitepassword:sitepassword,websitename:websitename});
    setRegistermsg("Merchant Registered Successfully");
  }

  return (
    <div className="joinOuterContainer">
      <div className="joinInnerContainer">
        <h1 className="heading">加盟店を登録</h1>
        <div>
          <input placeholder="加盟店名" className="joinInput mt-10" type="text" onChange={(event) => setName(event.target.value)} />
        </div>
        <div>
          <input placeholder="Merchant ID" className="joinInput mt-10" type="text" onChange={(event) => setMerchantid(event.target.value)} />
        </div>        
        <div>
          <input placeholder="Merchant Password" className="joinInput mt-10" type="password" onChange={(event) => setPassword(event.target.value)} />
        </div>
        <div>
          <input placeholder="Site ID" className="joinInput mt-10" type="text" onChange={(event) => setSiteid(event.target.value)} />
        </div>
        <div>
          <input placeholder="Site Password" className="joinInput mt-10" type="text" onChange={(event) => setSitepassword(event.target.value)} />
        </div>
        <div>
          <input placeholder="Website" className="joinInput mt-10" type="text" onChange={(event) => setWebsitename(event.target.value)} />
        </div>                        
        <button className={'button mt-10'} type="submit" onClick={register}>登録</button>
      </div>
      <h4 style={{color: "white"}}>{registermsg}</h4>
    </div>
  );
  
}

export default Add_merchant;
