import React from 'react';


import './InfoBar_nouser.css';
import chatappimage from "./test_image.jpg";

const InfoBar_nouser = ({ room }) => (
	<div className="myinfoBar_nouser">

    </div>



);

export default InfoBar_nouser;