import React from 'react';

import ScrollToBottom from 'react-scroll-to-bottom';

import ViewMessage from './ViewMessage/ViewMessage';

import './ViewMessages.css';

const ViewMessages = ({ messages }) => (
  <ScrollToBottom className="Historymessages">
    {messages.map((message, i) => <div key={i}><ViewMessage message={message.message} is_merchant={message.is_merchant} msg_time={message.msg_time} /></div>)}
  </ScrollToBottom>
);

export default ViewMessages;